import React from 'react';
import Header from '../../custom/segments/HeaderPage';
import Footer from '../../custom/segments/Footer';

export function Layout({ catalogType, catalogId, pageParams, children }) {
  return (
    <div>
      <Header catalogType={catalogType} catalogId={catalogId} pageParams={pageParams} />
      {children}
      <Footer />
    </div>
  );
}
