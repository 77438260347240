import React from 'react';
import PropTypes from 'prop-types';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import StyledHtmlViewer from '../../components/controls/StyledHtmlViewer';

function NewsletterInfo({ header, settings }) {
  // parameter management
  const defaultImage =
    'https://res.cloudinary.com/appsolut365/image/upload/v1654366466/appsolut365/webportal/demo/examples/blog2_t0r7jv.jpg';

  // rendering
  return (
    <StyledBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { rgba, linearGradient } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.8),
            rgba(gradients.info.state, 0.8)
          )}, url(${defaultImage})`,
        backgroundSize: 'cover',
      }}
    >
      <StyledBox py={6} pl={6} pr={{ xs: 6, sm: 12 }} mb="auto">
        <StyledTypography variant="h3" color="white" mb={1}>
          {header}
        </StyledTypography>
        <StyledHtmlViewer>
          <StyledTypography
            component="div"
            variant="body2"
            color="white"
            opacity={0.8}
            mb={3}
            dangerouslySetInnerHTML={{ __html: settings.contactAlias }}
          />
        </StyledHtmlViewer>
      </StyledBox>
    </StyledBox>
  );
}

NewsletterInfo.propTypes = {
  header: PropTypes.string,
  settings: PropTypes.instanceOf(Object),
};

export default NewsletterInfo;
