import * as ApiService from '../utils/axios';

class FlowService {
  sendNewsletterRequest = (payload) => {
    return new Promise((resolve, reject) => {
      ApiService.toFlow
        .post('/54mn0gas1o5wu1hp046g9muxcfmtiu8j', payload)
        .then((response) => {
          if (response.status === 200) resolve(response.data);
          else reject({ code: response.code, status: response.status, message: response.message });
        })
        .catch((error) => reject(error));
    });
  };

  sendNewsletterConfirm = (payload) => {
    return new Promise((resolve, reject) => {
      ApiService.toFlow
        .post('/xp5u4ekkva41hrbfke73fft3bjdrrypj', payload)
        .then((response) => {
          if (response.status === 200) resolve(response.data);
          else reject({ code: response.code, status: response.status, message: response.message });
        })
        .catch((error) => reject(error));
    });
  };
}

const flowService = new FlowService();
export default flowService;
