import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// appsolut base components
import NewsletterForm from '../elements/NewsletterForm';
import NewsletterInfo from '../elements/NewsletterInfo';
import ContentElement from '../elements/ContentElement';
import StyledBox from '../../components/controls/StyledBox';

function Newsletter({ header, title, teaser, body, footer, settings, showForm }) {
  // rendering
  return (
    <StyledBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item px={{ xs: 0, lg: 6 }}>
          <StyledBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: 'hidden' }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7}>
                <StyledBox p={2}>
                  <StyledBox px={3} py={{ xs: 2, sm: 6 }}>
                    <ContentElement title={title} teaser={teaser} body={body} />
                  </StyledBox>
                  {showForm && <NewsletterForm />}
                  {footer && (
                    <StyledBox px={3} py={{ xs: 2, sm: 6 }}>
                      <ContentElement footer={footer} />
                    </StyledBox>
                  )}
                </StyledBox>
              </Grid>
              <Grid item xs={12} lg={5} position="relative" px={0}>
                <NewsletterInfo header={header} settings={settings} />
              </Grid>
            </Grid>
          </StyledBox>
        </Grid>
      </Container>
    </StyledBox>
  );
}

Newsletter.defaultProps = {
  showForm: true,
};

Newsletter.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string,
  teaser: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.string,
  settings: PropTypes.instanceOf(Object),
  showForm: PropTypes.bool,
};

export default Newsletter;
